import { format } from "date-fns";
// import es from "date-fns/locale/es/index.js";
import { utcToZonedTime } from "date-fns-tz";

const getFormattedDate = (date, hour = true) => {
  const chileanDate = utcToZonedTime(date, "America/Santiago");

  return hour
    ? format(chileanDate, "d-MM-yyyy") //, { locale: es }
    : format(chileanDate, "d-MM-yyyy"); //, { locale: es }
  // return format(new Date(date), "dd-MM-yyyy hh:mm");
};

export default getFormattedDate;
